import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./project.css"

const Projects = () => {
  const completedProjects = [
    {
      title: 'Commercial Building',
      description: 'A state-of-the-art commercial building offering premium office and retail spaces, designed to elevate urban living and business experiences.',
      images: ['../images/project1-d.jpg', '../images/project1-a.jpg', '../images/project1-b.jpg', '../images/project1-c.jpg'],
    },
    {
      title: 'Rudrappa Residency',
      description: 'An elegant and luxurious residential building featuring modern amenities, spacious layouts, and contemporary design.',
      images: ['../images/project2-a.jpg', '../images/project2-b.jpg', '../images/project2-d.jpg'],
    },
    {
      title: 'Parvathamma Residency',
      description: 'A beautifully designed residential complex that blends modern architecture with sustainable practices for eco-friendly living.',
      images: ['../images/project3-c.jpg', '../images/project3-b.jpg', '../images/project3-a.jpg'],
    },
  ];
  

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);

  const openModal = (project) => {
    setCurrentProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentProject(null);
  };

  const renderProject = (project) => (
    <div
      key={project.title}
      className="p-4 bg-white shadow-lg rounded-lg transition duration-300 hover:shadow-xl hover:transform hover:-translate-y-2 cursor-pointer"
      onClick={() => openModal(project)}
    >
      <div className="flex justify-center">
        <img
          src={project.images[0]} // Show the first image as a preview
          alt={project.title}
          className="w-full h-64 object-cover rounded-lg mb-4"
        />
      </div>
      <h3 className="text-xl font-semibold mt-4 text-center">{project.title}</h3>
      <p className="text-gray-600 text-center mt-2">{project.description}</p>
    </div>
  );

  return (
    <section id="projects" className="container mx-auto px-4 py-16">
      <h2 className="text-4xl font-bold mb-12 text-gray-800">Our Projects</h2>

      {/* Completed Projects */}
      <div className="mb-16">
        <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {completedProjects.map(renderProject)}
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && currentProject && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center  z-50"
          onClick={closeModal}
        >
          <div
            className="relative bg-white p-8 rounded-lg shadow-lg w-[90%] max-w-2xl "

            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 bg-gray-100 rounded-full p-2"
              onClick={closeModal}
            >
              &times;
            </button>
            <Slider
              dots={true}
              infinite={true}
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              autoplay={true}
              autoplaySpeed={3000}
              className="rounded-lg mb-6"
            >
              {currentProject.images.map((image, index) => (
                <div key={index} className="flex justify-center">
                  <img
                    src={image}
                    alt={currentProject.title}
                    className="w-full h-96 object-cover rounded-lg"
                  />
                </div>
              ))}
            </Slider>
            <h3 className="text-2xl font-semibold text-center mb-4">{currentProject.title}</h3>
            <p className="text-gray-700 text-center">{currentProject.description}</p>
          </div>
        </div>
      )}
    </section>
  );
};

export default Projects;
