import React from 'react';
import CoreValues from './corevalue';
import './AboutUs.css';

const headerStyle = {
  fontFamily: "'Montserrat', sans-serif",
};

const AboutUs = () => {
  return (
    <section id="about" className="container mx-auto px-4 py-16">
      <h2 className="text-3xl font-bold mb-8 text-gray-800">About Us</h2>
      <div className="grid gap-8 md:grid-cols-2 flex flex-col lg:flex-row">
        <div className="order-2 lg:order-1">
          <p className="text-lg text-gray-700 leading-relaxed mb-6" style={headerStyle}>
            We are a leading construction company with over 4 years of experience in delivering top-quality construction projects across various sectors. Our team of skilled professionals is dedicated to transforming your vision into reality with precision and excellence.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mb-6" style={headerStyle}>
            <b>SMD Developers</b> offers a comprehensive range of services including <b>material contracting, planning, estimation, 3D elevation design, supervision, and structural design.</b> We take pride in delivering projects that not only meet but exceed client expectations, ensuring that every detail is carefully planned and executed.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mb-6" style={headerStyle}>
            Our portfolio includes a wide range of projects, from <b>residential buildings and commercial complexes to industrial facilities and infrastructure development</b>. We pride ourselves on our commitment to safety, sustainability, and innovation, ensuring that every project we undertake is completed to the highest standards.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mb-6" style={headerStyle}>
            At <b>SMD Developers</b>, we believe in building lasting relationships with our clients by consistently delivering exceptional results. Whether you're planning a small renovation or a large-scale construction project, we're here to help you every step of the way.
          </p>
        </div>
        <div className="flex items-center justify-center order-1 lg:order-2 about-img">
          <img
            src="../images/aboutus-1A.jpeg"
            alt="Construction Team"
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>
      </div>
    <section style={{ width: '100%' }} id="about" className="container w-full  px-4 py-16">

      <CoreValues />
      </section>
    </section>
  );
};

export default AboutUs;
