import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Projects = () => {
  const projects = [
    {
      title: 'Project 1',
      description: 'Commercial building',
      images: ['../images/project1-d.jpg', '../images/project1-a.jpg', '../images/project1-b.jpg','../images/project1-c.jpg'],
    },
    {
      title: 'Project 2',
      description: 'Rudrappa Residency',
      images: ['../images/project2-a.jpg', '../images/project2-b.jpg','../images/project2-d.jpg'],
    },
    {
      title: 'Project 3',
      description: 'Parvathamma Residency',
      images: ['../images/project3-c.jpg', '../images/project3-b.jpg','../images/project3-a.jpg',],
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);

  const openModal = (project) => {
    setCurrentProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setCurrentProject(null);
    setIsModalOpen(false);
  };

  return (
    <section id="projects" className="container mx-auto px-4 py-8 relative">
      <h2 className="text-3xl font-bold mb-8">Our Projects</h2>
      <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-8">
        {projects.map((project, index) => (
          <div
            key={index}
            className="project-item bg-white shadow-md rounded-lg overflow-hidden cursor-pointer hover:shadow-2xl transition duration-300 transform hover:-translate-y-2 cursor-pointer"
            onClick={() => openModal(project)}
          >
            <img src={project.images[0]} alt={project.title} className="w-full h-48 object-cover" />
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-4">{project.title}</h3>
              <p>{project.description}</p>
            </div>
          </div>
        ))}
      </div>
      <Link
        to="/projects"
        className="absolute right-0 bottom-0 bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 transition duration-300"
      >
        See All
      </Link>

      {/* Modal */}
      {isModalOpen && currentProject && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={closeModal}
        >
          <div
            className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={closeModal}
            >
              &times;
            </button>

            {/* Slider */}
            <Slider
              dots={true}
              infinite={true}
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              autoplay={true}
              autoplaySpeed={3000}
              className="mb-6"
            >
              {currentProject.images.map((image, index) => (
                <div key={index}>
                  <img src={image} alt={`${currentProject.title} ${index + 1}`} className="w-full h-48 object-cover rounded-lg" />
                </div>
              ))}
            </Slider>

            <h3 className="text-2xl font-semibold mb-4">{currentProject.title}</h3>
            <p className="text-gray-600">{currentProject.description}</p>
          </div>
        </div>
      )}
    </section>
  );
};

export default Projects;
