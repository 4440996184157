import React, { useState } from 'react';
import { FaHome, FaBuilding, FaHammer } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Services = () => {
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    {
      title: 'Residential Construction',
      description: 'We specialize in building high-quality homes tailored to your unique needs. Our residential construction services include custom home building, additions, and renovations. We work closely with you from the initial design phase to the final touches, ensuring that your home is both beautiful and functional. Our team is committed to using sustainable materials and the latest construction techniques to deliver a home that meets your expectations.',
      image: '../images/img4.jpeg',
      icon: <FaHome className="text-4xl text-blue-500 mb-4" />,
      additionalInfo: 'We offer full project management, including site preparation, foundation work, framing, roofing, and finishing. Our experienced team handles everything from small renovations to complete home builds with the utmost care and attention to detail.'
    },
    {
      title: 'Commercial Projects',
      description: 'Our commercial construction services are designed to meet the demands of businesses looking to build or expand their facilities. We handle office buildings, retail spaces, warehouses, and more. Our team is experienced in navigating the complexities of commercial projects, including zoning regulations, building codes, and project timelines. We ensure that your commercial space is built to the highest standards of quality and efficiency.',
      image: '../images/img2.jpg',
      icon: <FaBuilding className="text-4xl text-green-500 mb-4" />,
      additionalInfo: 'We also offer renovation services for existing commercial properties, ensuring that your space remains modern, efficient, and aligned with your business goals. From interior fit-outs to large-scale expansions, we deliver projects on time and within budget.'
    },
    {
      title: 'Renovations',
      description: 'Transform your home or business with our expert renovation services. We breathe new life into old spaces, whether it’s updating a kitchen, remodeling a bathroom, or adding new rooms to your home. Our renovation process is focused on minimizing disruption to your daily life while delivering results that exceed your expectations.',
      image: '../images/img8.jpg',
      icon: <FaHammer className="text-4xl text-red-500 mb-4" />,
      additionalInfo: 'We provide a full range of renovation services, including structural alterations, energy efficiency upgrades, and aesthetic improvements. Our goal is to enhance the functionality and appearance of your space while adding value to your property.'
    },
  ];

  const handleCardClick = (service) => {
    setSelectedService(service);
  };

  const handleClosePopup = () => {
    setSelectedService(null);
  };

  return (
    <section id="services" className="container mx-auto px-4 py-16 bg-blue-50 relative">
      <h2 className="text-3xl font-bold mb-12 text-gray-800">Our Services</h2>
      <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-8">
        {services.map((service, index) => (
          <div
            key={index}
            className="service-item p-8 bg-white shadow-lg rounded-lg hover:shadow-2xl transition duration-300 transform hover:-translate-y-2 cursor-pointer"
            onClick={() => handleCardClick(service)}
          >
            {service.icon}
            <h3 className="text-2xl font-semibold mb-4 text-gray-800">{service.title}</h3>
            <p className="text-gray-600">{service.description.slice(0, 100)}...</p>
          </div>
        ))}
      </div>
      <Link
        to="/services"
        className="absolute right-0 bottom-0 bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 transition duration-300"
      >
        See All
      </Link>
      {selectedService && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white rounded-lg p-8 w-[90%] max-h-[90%] max-w-sm sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-3xl relative overflow-auto">
      <button
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        onClick={handleClosePopup}
      >
        &#x2715;
      </button>
      <div className="flex justify-center mb-4">
        <img
          src={selectedService.image}
          alt={selectedService.title}
          className="w-full h-48 object-cover rounded-md"
        />
      </div>
      <div className="flex flex-start mb-4">{selectedService.icon}
      <h3 className="text-2xl mx-4 font-semibold text-gray-800 mb-4 py-1 ">{selectedService.title}</h3>

      </div>
      <p className="text-gray-600 mb-4">{selectedService.description}</p>
      <p className="text-gray-600">{selectedService.additionalInfo}</p>
    </div>
  </div>
)}

    </section>
  );
};

export default Services;
