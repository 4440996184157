import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaArrowUp, FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const footerStyle = {
    fontFamily: 'Calibri',
  };

  const footer2Style = {
    fontFamily: 'Garamond',
  };

  return (
    <footer className="bg-gray-800 text-white py-8 mt-10">
      <div className="container mx-auto text-center md:text-left grid grid-cols-1 md:grid-cols-4 gap-8">
        
        {/* Quick Links */}
        <div>
          <h3 className="text-2xl font-semibold text-white mb-4" style={footer2Style}>Quick Links</h3>
          <nav className="flex flex-col space-y-2">
            <Link 
              to="/" 
              className="text-xl flex items-center hover:text-gray-400 hover:scale-105 transition-transform duration-300"
              style={footerStyle}
            >
              Home
            </Link>
            <Link 
              to="/about" 
              className="text-xl flex items-center hover:text-gray-400 hover:scale-105 transition-transform duration-300"
              style={footerStyle}
            >
              About Us
            </Link>
            <Link 
              to="/services" 
              className="text-xl flex items-center hover:text-gray-400 hover:scale-105 transition-transform duration-300"
              style={footerStyle}
            >
              Services
            </Link>
            <Link 
              to="/projects" 
              className="text-xl flex items-center hover:text-gray-400 hover:scale-105 transition-transform duration-300"
              style={footerStyle}
            >
              Projects
            </Link>
            <Link 
              to="/contact" 
              className="text-xl flex items-center hover:text-gray-400 hover:scale-105 transition-transform duration-300"
              style={footerStyle}
            >
              Contact Us
            </Link>
          </nav>
        </div>

        {/* Social Networks */}
        <div>
          <h3 className="text-2xl font-semibold text-white mb-4" style={footer2Style}>Social Networks</h3>
          <div className="flex flex-col space-y-4">
            <a 
              href="https://facebook.com" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-xl flex items-center hover:text-gray-400 hover:scale-105 transition-transform duration-300"
              style={footerStyle}
            >
              <FaFacebookF className="mr-2"/> Facebook
            </a>
            <a 
              href="https://www.instagram.com/smddevelopers7/profilecard" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-xl flex items-center hover:text-gray-400 hover:scale-105 transition-transform duration-300"
              style={footerStyle}
            >
              <FaInstagram className="mr-2"/> Instagram
            </a>
            <a 
              href="https://wa.me/7022909075?text=Hi%20SMD%20Developers%2C%20I%20want%20to%20know%20more%20about%20your%20construction."
              target="_blank"
              rel="noopener noreferrer"
              className="text-xl flex items-center hover:text-gray-400 hover:scale-105 transition-transform duration-300"
              style={footerStyle}
            >
              <FaWhatsapp className="mr-2"/> WhatsApp
            </a>
            <a 
              href="https://www.linkedin.com/in/smd-developers-5b8a5033b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-xl flex items-center hover:text-gray-400 hover:scale-105 transition-transform duration-300"
              style={footerStyle}
            >
              <FaLinkedinIn className="mr-2"/> LinkedIn
            </a>
          </div>
        </div>

        {/* Address */}
        <div>
          <h3 className="text-2xl font-semibold text-white mb-4" style={footer2Style}>Our Address</h3>
          <p className="text-xl" style={footerStyle}>
            SMD Developers Pvt. Ltd.<br />
            #5,ground floor,<br />
            Sri venkateswara Theatre Building<br />
            Ajjampura 577547<br />
            Phone: +91 70229 09075<br />
            Email: Arunsd1508@gmail.com
          </p>
        </div>

        {/* Copyright & Scroll to Top */}
        <div className="text-center md:text-right">
          <div className="flex items-center justify-center md:justify-end mb-6 space-x-4">
            <p>&copy; 2024 SMD Developers. All Rights Reserved.</p>
            <button
              onClick={scrollToTop}
              className="p-2 bg-gray-700 hover:bg-gray-600 text-white rounded-full"
              aria-label="Scroll to top"
            >
              <FaArrowUp />
            </button>
          </div>
          <img
            src="../images/logo.png" // Replace with the actual path to your logo
            alt="SMD Developers Logo"
            className="h-[200px] w-[200px] mx-auto"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
