import React from 'react';
import { FaHome, FaBuilding, FaHammer } from 'react-icons/fa';

const ServiceLanding = () => {
  const services = [
    {
      title: 'Residential Construction',
      description: 'We specialize in building high-quality homes tailored to your unique needs. Our residential construction services include custom home building, additions, and renovations. We work closely with you from the initial design phase to the final touches, ensuring that your home is both beautiful and functional. Our team is committed to using sustainable materials and the latest construction techniques to deliver a home that meets your expectations.',
      image: '../images/img4.jpeg', // Replace with actual image paths
      icon: <FaHome className="text-4xl text-blue-500 mb-4" />,
      additionalInfo: 'We offer full project management, including site preparation, foundation work, framing, roofing, and finishing. Our experienced team handles everything from small renovations to complete home builds with the utmost care and attention to detail.'
    },
    {
      title: 'Commercial Projects',
      description: 'Our commercial construction services are designed to meet the demands of businesses looking to build or expand their facilities. We handle office buildings, retail spaces, warehouses, and more. Our team is experienced in navigating the complexities of commercial projects, including zoning regulations, building codes, and project timelines. We ensure that your commercial space is built to the highest standards of quality and efficiency.',
      image: '../images/img2.jpg',
      icon: <FaBuilding className="text-4xl text-green-500 mb-4" />,
      additionalInfo: 'We also offer renovation services for existing commercial properties, ensuring that your space remains modern, efficient, and aligned with your business goals. From interior fit-outs to large-scale expansions, we deliver projects on time and within budget.'
    },
    {
      title: 'Renovations',
      description: 'Transform your home or business with our expert renovation services. We breathe new life into old spaces, whether it’s updating a kitchen, remodeling a bathroom, or adding new rooms to your home. Our renovation process is focused on minimizing disruption to your daily life while delivering results that exceed your expectations.',
      image: '../images/img8.jpg',
      icon: <FaHammer className="text-4xl text-red-500 mb-4" />,
      additionalInfo: 'We provide a full range of renovation services, including structural alterations, energy efficiency upgrades, and aesthetic improvements. Our goal is to enhance the functionality and appearance of your space while adding value to your property.'
    },
    // Add more services as needed
  ];

  return (
    <section className="container mx-auto px-4 py-16 ">
      <h2 className="text-4xl font-bold mb-12 text-gray-800 text-center">Our Services</h2>
      <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
        {services.map((service, index) => (
          <div key={index} className="service-item p-8 bg-blue-50 shadow-lg rounded-lg transition duration-300 hover:shadow-2xl transform hover:-translate-y-2 lg:flex lg:flex-row-reverse">
            <div className="lg:w-1/2">
              <img src={service.image} alt={service.title} className="w-full h-48 object-cover mb-4 rounded-md lg:h-full" />
            </div>
            <div className="lg:w-1/2 lg:pr-8">
              <div className="flex justify-center lg:justify-start">{service.icon}<h3 className="text-2xl font-semibold ml-4 mb-4 text-gray-800 text-center lg:text-left">{service.title}</h3></div>
              
              <p className="text-gray-600 mb-4 text-center lg:text-left">{service.description}</p>
              <p className="text-gray-600 text-center lg:text-left">{service.additionalInfo}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServiceLanding;
