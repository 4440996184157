import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
    console.log("open");
  };

  const closeNav = () => {
    setIsNavOpen(false);
    console.log("close");
  };
  const headerStyle = {
    fontFamily: "Garamond",
  };
  
  const navItemsStyle = {
    fontFamily: "Teko",
  };

  return (
    <header className="sticky top-0 z-50 flex justify-between items-center p-4 bg-gray-800 text-white relative">
 <div className="flex items-center space-x-10">
 <Link to="/" className='block'>
    <img
      src='../images/logo.png' // Replace with the actual path to your logo
      alt="SMD Developers Logo"
      className="h-12 w-auto" // Adjust height and width as needed
    />
  </Link>
      <h1 className="text-2xl sm:text-2xl md:text-2xl lg:text-3xl text-white font-light " style={headerStyle}>SMD Developers</h1>
    
 </div>
 <button
        className="text-2xl md:hidden focus:outline-none"
        onClick={toggleNav}
      > 
        ☰
      </button>
      <nav
        className={`${
          isNavOpen ? 'block' : 'hidden'
        } absolute md:relative left-0 w-full z-10 top-16 md:top-0 md:w-auto md:flex flex-col md:flex-row bg-gray-800 md:bg-transparent p-4 md:p-0 shadow-lg md:shadow-none`}
      >
        <Link
          to="/"
          className="block py-2 px-4 text-white transition-all duration-300 ease-in-out hover:text-blue-400 text-lg hover:shadow-md hover:bg-transparent"
          onClick={closeNav}
          style={navItemsStyle}
        >
          Home
        </Link>
        <Link
          to="/about"
          className="block py-2 px-4 text-white transition-all duration-300 ease-in-out hover:text-blue-400 text-lg hover:shadow-md hover:bg-transparent"
          onClick={closeNav}
          style={navItemsStyle}

        >
          About Us
        </Link>
        <Link
          to="/services"
          className="block py-2 px-4 text-white transition-all duration-300 ease-in-out hover:text-blue-400 text-lg hover:shadow-md hover:bg-transparent"
          onClick={closeNav}
          style={navItemsStyle}

        >
          Services
        </Link>
        <Link
          to="/projects"
          className="block py-2 px-4 text-white transition-all duration-300 ease-in-out hover:text-blue-400 text-lg hover:shadow-md hover:bg-transparent"
          onClick={closeNav}
          style={navItemsStyle}

        >
          Projects
        </Link>
        <Link
          to="/contact"
          className="block py-2 px-4 text-white transition-all duration-300 ease-in-out hover:text-blue-400 text-lg hover:shadow-md hover:bg-transparent"
          onClick={closeNav}
          style={navItemsStyle}

        >
          Contact Us
        </Link>
      </nav>
    </header>
  );
};

export default Header;
