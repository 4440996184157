// src/components/Home.js

import React from 'react';
import Header from './Header';
import Hero from './Hero';
import AboutUs from './aboutus/AboutUs';
import Services from './services/ServiceTemplate';
import Projects from './projecte/ProjectTemplate';
import ContactForm from './contactUs/ContactForm';
import CoreValues from './aboutus/corevalue';
import Footer from './Footer';
import ImageSlider from './slider';

const Home = () => {
  return (
    <div className='bg-blue-50'>
      <Hero />
      <CoreValues />
      <Services />
      <Projects />
    <section id="projects"   style={{
    margin: window.innerWidth < 640 ? '0' : 'auto',
    width: window.innerWidth < 640 ? '100%' : '80%',

  }} className="container sm:w-full mx-auto px-4 py-8 sm:mx-0 flex flex-col md:flex-row items-start md:space-x-4">

        <ContactForm />
        <ImageSlider />
        </section>
    </div>
  );
};

export default Home;
