import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const navigate = useNavigate();

  const Mainstyle = {
    fontFamily: "Lato",
  };

  const Substyle = {
    fontFamily: "Cambria",
  };

  return (
    <section className="relative">
      <Slider {...settings}>
        <div className="relative">
          <img 
            src="../images/wallpaper.jpg" 
            alt="Construction Background" 
            className="w-full h-[60vh] md:h-[75vh] lg:h-[90vh] object-cover"
          />
          <div className="absolute inset-0 bg-opacity-50 flex items-center  text-white px-4">
            <div className="px-6 max-w-2xl">
              <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-bold leading-tight mb-4" style={Mainstyle}>
                Welcome to <br></br>SMD Developers
              </h1>
              <p className="text-sm md:text-lg lg:text-xl mb-8" style={Substyle}>
                Building your dreams with quality and precision.
              </p>
               <button
      onClick={() => navigate('/contact')}
      className="bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 transition duration-300"
    >
      Contact Us
    </button>
            </div>
          </div>
        </div>
        <div className="relative">
          <img 
            src="../images/img8.jpg" 
            alt="Construction Background" 
            className="w-full h-[60vh] md:h-[75vh] lg:h-[90vh] object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center  text-white px-4">
            <div className="px-6 max-w-2xl">
              <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-bold leading-tight mb-4" style={Mainstyle}>
                Welcome to <br></br>SMD Developers
              </h1>
              <p className="text-sm md:text-lg lg:text-xl mb-8" style={Substyle}>
                We Dig What You Dream
              </p>
               <button
      onClick={() => navigate('/contact')}
      className="bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 transition duration-300"
    >
      Contact Us
    </button>
            </div>
          </div>
        </div>
        <div className="relative">
          <img 
            src="../images/wallpaper1.jpg" 
            alt="Construction Background" 
            className="w-full h-[60vh] md:h-[75vh] lg:h-[90vh] object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center  text-white px-4">
            <div className="px-6 max-w-2xl">
              <h1 className="text-white text-3xl  md:text-4xl lg:text-5xl font-bold leading-tight mb-4" style={Mainstyle}>
                Welcome to <br></br>SMD Developers
              </h1>
              <p className="text-sm md:text-lg lg:text-xl mb-8" style={Substyle}>
                Construct Your Future
              </p>
               <button
      onClick={() => navigate('/contact')}
      className="bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 transition duration-300"
    >
      Contact Us
    </button>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default Hero;
