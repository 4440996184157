import React from 'react';
import { FaLightbulb, FaHandshake, FaStar, FaChartLine, FaDollarSign, FaUsers } from 'react-icons/fa';

const CoreValues = () => {
  return (
    <section id="core-values" style={{
      margin: window.innerWidth < 640 ? '0' : 'auto',
      width: window.innerWidth < 640 ? '100%' : '80%',
  
    }} className="container p-8 bg-blue-50">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">WHY SHOULD YOU CHOOSE US?</h2>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6 text-center">
        <div className="core-value-item py-8 px-4">
          <div className="icon-container hover:shadow-2xl transition duration-300 transform hover:-translate-y-2 w-32 h-32 mx-auto flex items-center justify-center bg-blue-100 rounded-full mb-4">
            <FaLightbulb className="text-4xl text-blue-500" />
          </div>
          <h3 className="text-xl font-semibold mb-4 text-gray-800">Creative & Expertise</h3>
        </div>
        <div className="core-value-item py-8 px-4">
          <div className="icon-container hover:shadow-2xl transition duration-300 transform hover:-translate-y-2 w-32 h-32 mx-auto flex items-center justify-center bg-blue-100 rounded-full mb-4">
            <FaHandshake className="text-4xl text-blue-500" />
          </div>
          <h3 className="text-xl font-semibold mb-4 text-gray-800">Honest & Valuable Advice</h3>
        </div>
        <div className="core-value-item py-8 px-4">
          <div className="icon-container hover:shadow-2xl transition duration-300 transform hover:-translate-y-2 w-32 h-32 mx-auto flex items-center justify-center bg-blue-100 rounded-full mb-4">
            <FaStar className="text-4xl text-blue-500" />
          </div>
          <h3 className="text-xl font-semibold mb-4 text-gray-800">Excellent Customer Service</h3>
        </div>
        <div className="core-value-item py-8 px-4">
          <div className="icon-container hover:shadow-2xl transition duration-300 transform hover:-translate-y-2 w-32 h-32 mx-auto flex items-center justify-center bg-blue-100 rounded-full mb-4">
            <FaChartLine className="text-4xl text-blue-500" />
          </div>
          <h3 className="text-xl font-semibold mb-4 text-gray-800">Over 3 Yrs Of Experience</h3>
        </div>
        <div className="core-value-item py-8 px-4">
          <div className="icon-container hover:shadow-2xl transition duration-300 transform hover:-translate-y-2 w-32 h-32 mx-auto flex items-center justify-center bg-blue-100 rounded-full mb-4">
            <FaDollarSign className="text-4xl text-blue-500" />
          </div>
          <h3 className="text-xl font-semibold mb-4 text-gray-800">Competitive Prices</h3>
        </div>
        <div className="core-value-item py-8 px-4">
          <div className="icon-container hover:shadow-2xl transition duration-300 transform hover:-translate-y-2 w-32 h-32 mx-auto flex items-center justify-center bg-blue-100 rounded-full mb-4">
            <FaUsers className="text-4xl text-blue-500" />
          </div>
          <h3 className="text-xl font-semibold mb-4 text-gray-800">Polite & Helpful Staff</h3>
        </div>
      </div>
    </section>
  );
};

export default CoreValues;
