import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    mobile: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      await emailjs.sendForm('service_ziz52of', 'template_j0iycb3', e.target, 'R8tdje7FJrkM3FReG');
      setMessage('Message sent successfully!');
      setFormState({ name: '', email: '', message: '', mobile: '' });
    } catch (err) {
      setMessage(`Failed to send message: ${err.text}`);
    } finally {
      setLoading(false);
    }
  };

  // WhatsApp URL with pre-filled message
  const whatsAppMessage = 'Hi SMD Developers, I want to know more about your construction.';
  const whatsAppUrl = `https://wa.me/7022909075?text=${encodeURIComponent(whatsAppMessage)}`;

  // Handle WhatsApp button click
  const handleWhatsAppClick = () => {
    window.open(whatsAppUrl, '_blank');
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold mb-6 text-center">Contact Us</h2>
      <form onSubmit={handleSubmit} className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow-lg">
        {message && <p className="text-center mb-4">{message}</p>}
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">Name:</label>
          <input
            id="name"
            type="text"
            name="name"
            value={formState.name}
            placeholder="Enter your name"
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">Email:</label>
          <input
            id="email"
            type="email"
            name="email"
            value={formState.email}
            onChange={handleChange}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300"
            placeholder="Enter your email"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="mobile" className="block text-gray-700 font-semibold mb-2">Mobile Number:</label>
          <input
            id="mobile"
            type="text"
            name="mobile"
            value={formState.mobile}
            onChange={(e) => {
              if (/^\d{0,10}$/.test(e.target.value)) {
                handleChange(e);
              }
            }}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300"
            placeholder="Enter your mobile number"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="message" className="block text-gray-700 font-semibold mb-2">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formState.message}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="5"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          disabled={loading}
        >
          {loading ? 'Sending...' : 'Send'}
        </button>
      </form>

      {/* WhatsApp Button with pre-filled message */}
      <div className="mt-6 text-center">
        <button
          onClick={handleWhatsAppClick}
          className="inline-flex items-center bg-green-500 text-white px-6 py-3 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 w-full sm:w-auto"
        >
          <img
             src="../images/whatsapp3.png"  // Replace with your WhatsApp icon path
            alt="WhatsApp Icon"
            className="w-6 h-6 mr-2"
          />
          Chat with us on WhatsApp
        </button>
      </div>
    </div>
  );
};

export default ContactForm;
